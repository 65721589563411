<template>
      <div class="overlay">
            <!-- <img src="@/assets/images/loader.gif" alt="loader"> -->
            <!-- <img src="@/assets/icons/svg/loading.svg" alt="loader"> -->
            <div class="loadingio-spinner-eclipse-fhfii4ea6v">
                  <div class="ldio-jgo24qe1hc">
                        <div></div>
                  </div>
            </div>
      </div>
</template>

<script>
export default {
      name: 'OverlayLoader'
}
</script>

<style lang="scss">
@import "OverlayLoader";

.overlay {
      img {
            width: 100px;
      }
}

@keyframes ldio-jgo24qe1hc {
      0% {
            transform: rotate(0deg)
      }

      50% {
            transform: rotate(180deg)
      }

      100% {
            transform: rotate(360deg)
      }
}

.overlay {

      .ldio-jgo24qe1hc div {
            position: absolute;
            animation: ldio-jgo24qe1hc 1s linear infinite;
            width: 69px;
            height: 69px;
            top: 34.5px;
            left: 34.5px;
            border-radius: 50%;
            box-shadow: 0 2.346px 0 0 #ffffff;
            transform-origin: 34.5px 35.673px;
      }

      .loadingio-spinner-eclipse-fhfii4ea6v {
            width: 138px;
            height: 138px;
            display: inline-block;
            overflow: hidden;
            background: none;
      }

      .ldio-jgo24qe1hc {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(1);
            backface-visibility: hidden;
            transform-origin: 0 0;
            /* see note above */
      }

      .ldio-jgo24qe1hc div {
            box-sizing: content-box;
      }
}
</style>