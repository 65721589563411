<template>
      <transition name="fade">
            <div class="popup-modal" v-if="isVisible">
                  <div class="window">
                        <slot></slot>
                  </div>
            </div>
      </transition>
</template>

<script>
export default {
    name: 'PopupModal',

    data: () => ({
        isVisible: false,
    }),

    methods: {
        open() {
            this.isVisible = true
        },

        close() {
            this.isVisible = false
        },
    },
}
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.popup-modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 12;
    justify-content: center;
    z-index: 9999;
}

.window {
    width: 450px;
    max-width: 450px;
    margin: 0px auto;
    background: #17181A 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid #FFFFFF27;
    transition: all 0.3s ease;
    color: #fff;
}
</style>