<template>
    <div class="main-notifications" id="main-notifications">
        <div class="notification-header">
            <h3>Notifications</h3>
            <div class="d-flex align-items-center" v-if="unReadNotificationsCount > 0">
                <img src="@/assets/icons/png/check.png" alt="check">
                <a class="cursor-pointer" @click="readNotificationById(getUnreadNotificationsId())">Mark all as read</a>
            </div>
        </div>

        <ul class="cat-tab">
            <li @click="changeNotifTab('All')" :class="{ 'active' : notificationTab == 'All' }">
                <span class="tab-name">All</span>
                <span class="count">{{ notificationsArray.length }}</span>
            </li>
            <li @click="changeNotifTab('Unread')" :class="{ 'active' : notificationTab == 'Unread' }">
                <span class="tab-name">Unread</span>
                <span class="count">{{ unReadNotificationsCount }}</span>
            </li>
            <li @click="changeNotifTab('Read')" :class="{ 'active' : notificationTab == 'Read' }">
                <span class="tab-name">Read</span>
                <span class="count">{{ readNotificationsCount }}</span>
            </li>
        </ul>

        <div class="notifications hide-scrollbar" v-if="notificationTab == 'All'">
            <div class="notif-row" v-for="(notif , index) in notificationsArray" :key="index">
                <div class="notif-detail" @click="openNotification(notif)">
                    <!-- <img src="@/assets/images/user-default.png" alt="notif-image"> -->
                    <span class="red-notif" v-if="notif.is_read == 0"></span>
                    <div class="text">
                        <p>{{ notif.data || '-' }}</p>
                        <span class="date">
                            {{ notif.created_at || '-' }}
                        </span>
                    </div>
                </div>
                <a class="cursor-pointer remove" v-if="notif.is_read == 0" @click="readNotificationById([notif.id])">
                    <img src="@/assets/icons/svg/close.svg" alt="remove">
                </a>
            </div>
        </div>

        <div class="notifications hide-scrollbar" v-else-if="notificationTab == 'Read'">
            <div class="notif-row" v-for="(notif , index) in notificationsArray" :key="index" v-show="notif.is_read == 1">
                <div class="notif-detail" @click="openNotification(notif)">
                    <!-- <img src="@/assets/images/user-default.png" alt="notif-image"> -->

                    <div class="text">
                        <p>{{ notif.data || '-' }}</p>
                        <span class="date">
                            {{ notif.created_at || '-' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="notifications hide-scrollbar" v-else-if="notificationTab == 'Unread'">
            <div class="notif-row" v-for="(notif , index) in unReadNotificationsArray" :key="index">
                <div class="notif-detail" @click="openNotification(notif)">
                    <!-- <img src="@/assets/images/user-default.png" alt="notif-image"> -->
                    <span class="red-notif"></span>
                    <div class="text">
                        <p>{{ notif.data || '-' }}</p>
                        <span class="date">
                            {{ notif.created_at || '-' }}
                        </span>
                    </div>
                </div>
                <a class="cursor-pointer remove" @click="readNotificationById([notif.id])">
                    <img src="@/assets/icons/svg/close.svg" alt="remove">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { getNotifications } from "@/services/axios/getNotifications.service";
    import { getUnreadNotifications } from "@/services/axios/getUnreadNotifications.service";
    import { readNotification } from "@/services/axios/readNotification.service";

    import { toast } from 'vue3-toastify';

    import store from '@/store';

    export default {
        name: 'notificationsComponent',
        props: {
            accessToken: String
        },

        data() {
            return {
                notificationsArray: [],
                unReadNotificationsArray: [],

                notificationTab: 'All',

                readNotificationsCount: 0,
                unReadNotificationsCount: 0,

                toastConfig: {
                    autoClose: 3000,
                    position: toast.POSITION.BOTTOM_LEFT,
                    type: toast.TYPE.SUCCESS,
                    theme: toast.THEME.COLORED
                }
            }
        },

        created() {
            this.getAllNotifications();
        },

        mounted() {
            setInterval(() => {
                this.getAllNotifications();
            }, 20000);
        },

        methods: {
            getNotifications,
            async getAllNotifications() {
                if(!this.accessToken && !localStorage.getItem('accessToken')) {
                    return;
                }

                const res = await this.getNotifications();
                if(!res) {
                    return;
                }
                this.notificationsArray = res.data.result;

                if(!this.notificationsArray.length) { return; }
                this.readNotificationsCount = this.notificationsArray.filter((n) => n.is_read == 1).length;
                this.unReadNotificationsCount = this.notificationsArray.filter((n) => n.is_read == 0).length;

                if(this.unReadNotificationsCount > 0) {
                    store.commit('mutationer', { hasNotification: true });

                } else {
                    store.commit('mutationer', { hasNotification: false });      
                }
            },

            getUnreadNotifications,
            async getUnreadNotificationsData() {
                const res = await this.getUnreadNotifications();
                this.unReadNotificationsArray = res.data.result;
            },

            changeNotifTab(tab) {
                if(tab == 'Unread') {
                    this.getUnreadNotificationsData();
                } else {
                    this.getNotifications();
                }

                this.notificationTab = tab;
            },

            getUnreadNotificationsId() {
                let array = [];
                for(let i = 0; i < this.notificationsArray.length; i++) {
                    if(this.notificationsArray[i].is_read == 0) {
                        array.push(this.notificationsArray[i].id);
                    }
                }

                return array;
            },

            openNotification(notif) {
                if(notif.resource == 'project') {
                    this.readNotificationById([notif.id]);

                    this.$router.push('/');

                    setTimeout(() => {
                        document.getElementById('toggle-notif').click();
                    }, 10);
                    
                } else if(notif.resource == 'item' || notif.resource == 'step') { 
                    this.readNotificationById([notif.id]);

                    const projectId = notif.resource_id.split('-')[0];
                    const serviceId = notif.resource_id.split('-')[1];

                    store.commit('mutationer', { selectedProjectId: projectId });
                    localStorage.setItem('selectedProjectId' , projectId);

                    store.commit('mutationer', { selectedServiceId: serviceId });
                    localStorage.setItem('selectedServiceId' , serviceId);

                    this.$router.push('/view-steps' + '?serviceId=' + serviceId);

                    setTimeout(() => {
                        document.getElementById('toggle-notif').click();
                    }, 10);                }
            },

            readNotificationById(notificationIds) {
                const form = {
                    ids: notificationIds
                }
                readNotification(form).then(res => {
                    if(!res){
                        return;
                    }

                    this.getAllNotifications();
                    // toast(res?.data?.message, this.toastConfig);

                }).catch(e => {
                    console.log(e)
                })
            },
        },

        watch: {
            accessToken: function(value) {
                if(value) {
                    this.getAllNotifications();
                }
            }
        }
    }
</script>