import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "404",
            meta: { layout: "full", title: "Page introuvable" },
            component: lazyLoad('404'),
      },
      {
            path: '/',
            name: 'homePage',
            component: lazyLoad('home/Home'),
            meta: { layout: "with-header", title: "Home" , requiresAuth: true },
            props: true,
      },
      {
            path: '/profile',
            name: 'Profile',
            component: lazyLoad('profile/Profile'),
            meta: { layout: "with-header", title: "Profile" , requiresAuth: true },
            props: true,
      },
      {
            path: '/provided-services',
            name: 'providedServices',
            component: lazyLoad('provicedServices/ProvidedServices'),
            meta: { layout: "default", title: "providedServices" , requiresAuth: true },
            props: true,
      },
      {
            path: '/view-steps',
            name: 'viewSteps',
            component: lazyLoad('viewSteps/ViewSteps'),
            meta: { layout: "default", title: "viewSteps" , requiresAuth: true },
            props: true,
      },
      {
            path: '/login',
            name: 'login',
            component: lazyLoad('auth/Login'),
            meta: { layout: "full", title: "login" , requiresAuth: false },
            props: true,
      },
      {
            path: '/forget-password',
            name: 'forgetPassword',
            component: lazyLoad('auth/ForgetPasswordPage'),
            meta: { layout: "full", title: "ForgetPasswordPage" , requiresAuth: false },
            props: true,
      }
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

router.beforeEach((toRoute, fromRoute, next) => {
      window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
      if (toRoute.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (!store.getters.accessToken && !localStorage.getItem('accessToken') && !toRoute.query.token) {
              next({ name: 'login' })
            } else {
              next(); // go to wherever I'm going
            }
          } else {
            next(); // does not require auth, make sure to always call next()!
      }
      next();
})

export default router;
