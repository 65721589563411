<template>
      <popup-modal ref="popup">
            <div class="modal-header">
                <h3 class="title">{{ title }}</h3>
                <img src="@/assets/icons/svg/close.svg" class="cursor-pointer" alt="close" @click="_cancel">
            </div>
            <p class="message">{{ message }}</p>
            <div class="btns">
                <button class="ok-btn" @click="_cancel">
                    {{ cancelButton }}
                </button>
                <button class="cancel-btn" @click="_confirm">
                    {{ okButton }}
                </button>
            </div>
      </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
    name: "ConfirmDialogue",

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: "No", // text for cancel button

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title;
            this.message = opts.message;
            this.okButton = opts.okButton;
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton;
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },

        _confirm() {
            this.$refs.popup.close();
            this.resolvePromise(true);
        },

        _cancel() {
                this.$refs.popup.close();
                this.resolvePromise(false);
                // Or you can throw an error
                // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    },
};
</script>

<style scoped lang="scss">

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid #FFFFFF27;
    h3{
        margin: 0;
        color: #FFFFFF;
        font-size: 18px;
    }
    svg{
        cursor: pointer;
    }
}

.message {
    font-size: 17px;
    margin: 20px 0;
    text-align: center;
}
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 20px;
}

.ok-btn , .cancel-btn {
    width: 100px;
    padding: 13px 15px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
}

.cancel-btn {
    background: transparent;
    box-shadow: 0px 2px 2px #00000029;
    border-radius: 30px;
    color: #fff;
    border: 1px solid #fff;
}

.ok-btn {
    background: #2560E6 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    color: #fff;
    border: none;
}
</style>