import axios from 'axios';
import store from '@/store'

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

// eslint-disable-next-line no-unused-vars
httpClient.interceptors.request.use((config) => {
    const token = store.state.accessToken || localStorage.getItem('accessToken');
    token ? config.headers.Authorization =  'Bearer' + ' ' +token : '';

    return config;
}, (error) => {
    return Promise.reject(error);
});

httpClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        Promise.reject(error);
    }
);

export default httpClient;